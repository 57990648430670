import { Outlet } from "react-router-dom";
import "./layout.css";

function Layout() {
  return (
    <>
      <div className="pattern" />
      <Outlet />
    </>
  );
}

export default Layout;
