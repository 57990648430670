import "./home.css";
import logo from "../assets/logo.png";

function Home() {
  return (
    <div className="container">
      <img src={logo} className="logo" alt="iuvant" />
      <h3>is coming soon...</h3>
    </div>
  );
}

export default Home;
