import {
  Route,
  createRoutesFromElements,
  createBrowserRouter,
} from "react-router-dom";
import Layout from "./pages/layout";
import Home from "./pages/home";
import Terms from "./pages/terms";

function Router() {
  return createBrowserRouter(
    createRoutesFromElements(
      <Route element={<Layout />}>
        <Route path="/" element={<Home />} />
        <Route path="terms" element={<Terms />} />
      </Route>
    )
  );
}

export default Router;
