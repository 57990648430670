import logo from "../assets/logo.png";
import "./terms.css";

function Terms() {
  return (
    <div className="container-terms">
      <h1>terms and conditions</h1>
      <img src={logo} className="logo" alt="iuvant" />
      <h2>Choose your language / Seleccione el idioma </h2>
      <div className="language-container">
        <a href="/docs/terminos-y-condiciones.pdf">Leer versión en Español</a>
        <a href="/docs/terms-and-conditions.pdf">read English version</a>
      </div>
    </div>
  );
}

export default Terms;
